import React from "react";
import "./EventList.css";

const EventList = () => {
  return (
    <div>
      <h3 className="gallerytitle"> Gallery</h3>
      <h3 className="gallerysubtitle"> Sunshine-Grand</h3>
    </div>
  );
};

export default EventList;
